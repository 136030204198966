import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames/bind';

// Styles
import styles from './PawGroup.styl';


const cx = classNames.bind(styles);

const PawGroup = ({classname}) => {


  return (
    <div className={cx('PawGroup', classname)}>

    </div>
  );
};

export default PawGroup;
