import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames/bind';


//Icons
import CrossIcon from '../../../icons/cross-grey.svg';

// Hooks
import {useHatiko} from "../useHatiko";

// Styles
import styles from './ProductCardHatikoBanner.styl';


const cx = classNames.bind(styles);

const ProductCardHatikoBanner = () => {

  const {isHatikoComboAdded} = useHatiko()
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(!!isHatikoComboAdded)
  }, [isHatikoComboAdded, setVisible]);

  const closeHandler = () => {
    setVisible(false)
  }

  return (
    <div className={cx('ProductCardHatikoBanner', {'ProductCardHatikoBanner_hidden': !isVisible})}>
      <div className={cx('ProductCardHatikoBanner__image')}/>
      <div className={cx('ProductCardHatikoBanner__close')} onClick={closeHandler}>
        <CrossIcon width={12} height={12}/>
      </div>
    </div>
  );
};

export default ProductCardHatikoBanner;
