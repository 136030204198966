import React, { useCallback, useState, useEffect } from 'react';
import classNames from "classnames/bind";
import styles from "./FootballCategoryDecor.styl";
import ball from "../../assets/ball.png";

const cx = classNames.bind(styles);

const FootballCategoryDecor = ({ className, ...props }) => {
  const [animClass, setAnimClass] = useState('');
  const [animationTimeout, setAnimationTimeout] = useState(null);

  const addAnimation = useCallback(() => {
    setAnimClass(cx('FootballCategoryDecor__animation'));
    const timeoutId = setTimeout(() => {
      setAnimClass('');
    }, 5000);
    setAnimationTimeout(timeoutId);
  }, []);

  const clearAnimationTimeout = () => {
    if (animationTimeout) {
      clearTimeout(animationTimeout);
    }
  };

  useEffect(() => {
    return () => {
      clearAnimationTimeout();
    };
  }, []);

  return (
    <div
      className={cx('FootballCategoryDecor', animClass, className)}
      {...props}
      onClick={() => {
        clearAnimationTimeout();
        addAnimation();
      }}
    >
      <img src={ball} className={cx('FootballCategoryDecor_image', )} alt="Football" />
    </div>
  );
};

export default FootballCategoryDecor;
