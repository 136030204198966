import {useSelector} from "react-redux";
import {useCallback, useMemo, useState} from "react";
import {isThemeHatiko} from "../../state/modules/city/selectors";

export const useHatiko = () => {

  const isThemeHatikoOn = useSelector(isThemeHatiko)

  const goods = useSelector(state => state.catalog.products.list);
  const composition = useSelector(state => state.cart.composition) || [];

  const hatikoGood = useMemo(() => {
    if (isThemeHatikoOn) {
      return goods.find(good => good.category === 'combo')
    }
  }, [goods, isThemeHatikoOn])

  const hatikoVariationId = useMemo(() => {
    if (hatikoGood) {
      return hatikoGood?.variations[0]?.id || ''
    }
  }, [hatikoGood])

  const compositionIds = useMemo(() => {
    return composition.map(good => good.item.id)
  }, [composition])

  const isHatikoComboAdded = useMemo(() => {
    if (hatikoVariationId) {
      return compositionIds.includes(hatikoVariationId)
    }
  }, [hatikoVariationId, compositionIds])

  const isHaticoGood = useCallback((goodId) => {
    return goodId === hatikoGood?.id
  }, [isHatikoComboAdded])

  const isHatiko = useMemo(() => {
    return isThemeHatikoOn && hatikoGood && hatikoVariationId
  }, [isThemeHatikoOn, hatikoGood, hatikoVariationId])

  const shouldMainHatikoBannerShow = useMemo(() => {
    return isHatiko && !isHatikoComboAdded
  }, [isHatiko, isHatikoComboAdded])

  return {
    isHatiko,
    isHaticoGood,
    shouldMainHatikoBannerShow,
    isHatikoComboAdded
  }
}
